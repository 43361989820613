.home__notice {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(188, 189, 189, 0.34);
    .home__notice__warning {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 26rem;
      width: 90%;
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid #dee8f2;
  
      .home__notice__warningClose {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
      }
      .home__notice__warningText {
        font-family: Montserrat-Bold;
        color: #01a27e;
        font-size: 1.6rem;
        margin: 1.5rem 0;
        text-align: center;
      }
      .home__notice__warningButton {
        width: 12rem;
        height: 4.4rem;
        background: rgba(20, 117, 223, 0);
        border: 1px solid #01a27e;
        border-radius: 20px;
        font-family: Montserrat-Regular;
        font-size: 1.6rem;
        color: #01a27e;
        cursor: pointer;
      }
    }
  }