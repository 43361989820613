* {
  margin: 0;
  padding: 0;
}
body {
  -webkit-overflow-scrolling: touch;
}
@font-face {
  font-family: DMSans-Regular;
  src: url(./fonts/DMSANS-REGULAR.TTF);
}
@font-face {
  font-family: DMSans-Bold;
  src: url(./fonts/DMSANS-BOLD.TTF);
}
@font-face {
  font-family: DMSans-Medium;
  src: url(./fonts/DMSANS-MEDIUM.TTF);
}
@font-face {
  font-family: Poppins-Medium;
  src: url(./fonts/POPPINS-MEDIUM.TTF);
}
@font-face {
  font-family: Poppins-Semi-Bold;
  src: url(./fonts/Poppins-SemiBold.TTF);
}
@font-face {
  font-family: Poppins-Bold;
  src: url(./fonts/POPPINS-BOLD.OTF);
}
@font-face {
  font-family: Proxima-Nova-Sbold;
  src: url(./fonts/Proxima-Nova-Sbold.OTF);
}
@font-face {
  font-family: Myriad-Pro-Regular;
  src: url(./fonts/Myriad-Pro-Regular.TTF);
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(./fonts/Montserrat-Regular.ttf);
}
@font-face {
  font-family: Montserrat-Bold;
  src: url(./fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(./fonts/Montserrat-Medium.ttf);
}
.app__loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}
.loader {
  position: relative;
  top: 40%;
  left: calc(50% - 30px);
}
.Toastify {
  position: relative;
  z-index: 10000;
}
.Toastify__toast-body {
  font-size: 1.5rem;
}
.Toastify__toast-container {
  top: 70px !important;
}
