.time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat-Regular;
  font-size: 13px;
  color: #0e2c0e;
  .time-img{
    width: 1.8rem;
  }
  .time-remain {
    font-family: Montserrat-Regular;
    font-size: 13px;
    color: #0e2c0e;
  }
  .time__timeValueContainer {
    margin-left: 20px;
    p {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
    }
    .time__timeValue {
      margin-left: 10px;
      font-family: Montserrat-Bold;
      font-size: 13px;
    }
  }
}
