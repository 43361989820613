.receiver-info-wrap {
  width: 100%;
  overflow: hidden;
  .choose-bank{
    margin-bottom: 1rem !important;
    font-size: 1.4rem;
  }
  .bank-payment-img-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: -1rem;
    clear: both;
  }

  .bank-payment-img-item {
    width: 50%;
    margin-bottom: 1.4rem;

    p {
      font-size: 0.9rem;
      font-weight: bold;
      margin-bottom: 0;
      color: #666;
      line-height: 1.3rem !important;
    }

    .bank-name {
      margin-top: 0.2rem;

      &.supported {
        color: #727171;
      }
    }

    .bank-account {
      margin-top: -0.4rem;
      margin-bottom: -0.4rem;

      &.supported {
        color: #01a27e;
      }
    }

    
  }

  .bank-payment-img {
    width: 13rem;
    height: 44px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: -0.3rem;
    cursor: pointer;

    &.supported {
      border: 1px solid #727171;
    }

    &.active {
      border: 2px solid #01a27e;
    }

    img {
      width: 100%;
      filter: grayscale(100%);
      margin-top: -0.2rem;

      &.supported {
        filter: grayscale(0)
      }
    }
  }

  .receiver-info-box {
    p {
      margin: 0;
    }
    position: absolute;
    top: 5rem;
    bottom: 27rem;
    width: 90%;
    padding: 5%;
    background-color: #ecf3ec;
    overflow: auto;
    z-index: 200;
    .title {
      width: 100%;
      height: 1.3rem;
      font-size: 1.3rem;
      font-family: Poppins;
      font-weight: 600;
      color: #000000;
      margin-top: 2.7rem;
      margin-bottom: 2.7rem;
    }
  }
  .form-item-box {
    margin-bottom: 2rem;
  }
  .submit-btn {
    position: fixed;
    width: 90%;
    height: 5.2rem;
    bottom: 0.85rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
    opacity: 0;
    -webkit-tap-highlight-color: transparent;
  }
}
.checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Montserrat-Regular;
  font-size: 1.3rem;
  margin-bottom: 1rem !important;
  .privacy-box {
    margin-left: 6px;
    color: #6a6f7a;
    font-family: Montserrat-Regular;
    font-size: 1.3rem;
  }
  .privacy {
    color: #0376ff;
    cursor: pointer;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #01a27e !important;
  }
  .MuiSvgIcon-fontSizeSmall {
    font-size: 2rem;
  }
}
.privacy-policy-error {
  font-size: 1.2rem;
  font-family: Montserrat-Regular;
  color: #f44336;
  margin-left: 1.3rem !important;
  margin-bottom: 1rem !important;
}

._loading_overlay_overlay{
  background: rgba(0,0,0,0.3) !important;
}