.method-wrap {
  width: 100%;
  height: auto;
  overflow: hidden;
  .method-box {
    position: absolute;
    top: 5.5rem;
    bottom: 9rem;
    font-size: 1.3rem;
    width: 90%;
    padding: 5%;
    background-color: #ecf3ec;
    .method-group {
      width: 100%;
      .MuiFormControl-root {
        width: 100%;
      }
      .MuiFormControlLabel-label {
        color: #000000;
        font-family: Montserrat-Bold;
        font-weight: bold;
        font-size: 1.3rem;
      }
      .method-item {
        width: 90%;
        background-color: #fff;
        margin-top: 1.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5%;
        border-radius: 3px;
        .banktranfer-img {
          width: 2.5rem;
        }
        .pix-img {
          width: 4rem;
          margin-right: -0.5rem;
        }
        .paypal-img{
          width: 10rem;
        }
      }
    }
  }
  .submit-btn {
    position: fixed;
    width: 34rem;
    height: 5.5rem;
    bottom: 0.8rem;
    left: 50%;
    margin-left: -17rem;
    z-index: 300;
    opacity: 0;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #01a27e !important;
}
.MuiFormControlLabel-root {
  width: 100% !important;
}
